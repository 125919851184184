// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/header/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/header/index.tsx");
  import.meta.hot.lastModified = "1728407620183.1345";
}
// REMIX HMR END

import { Link } from '@remix-run/react';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import { useRootLoader } from '~storefront/utils/useRootLoader';
import clsx from 'clsx';
import { UserButton } from './UserButton';
export const navLinks = [{
  name: 'Home',
  link: '/'
}, {
  name: 'About',
  link: '/about'
}, {
  name: 'Locations',
  link: '/location'
}];
export function Header({
  onLogOutClick,
  onCartIconClick,
  cartQuantity
}) {
  _s();
  const data = useRootLoader();
  const isSignedIn = !!data.activeCustomer.activeCustomer?.id;
  const homeLogo = data.activeChannel.customFields?.storeIcon?.preview;
  return <header className={clsx('sticky top-0 z-10', 'bg-black from-zinc-700 to-gray-900 transform shadow sm:px-6 border-b border-gray-200', 'dark:bg-slate-900 dark:border-slate-500')}>
      <div className="max-w-7xl mx-auto px-4 py-1 flex justify-between items-center space-x-4">
        <div className="w-auto mx-auto flex justify-start items-center space-x-8 flex-1">
          <Link to="/" className="w-12">
            <img src={homeLogo} alt="Storefront Logo" />
          </Link>
          <div className="space-x-12 hidden sm:block">
            {navLinks.map(navLink => <Link className={clsx('text-sm md:text-base text-gray-200 hover:text-white', 'dark:text-slate-300')} to={navLink.link} prefetch="intent" key={navLink.name}>
                {navLink.name}
              </Link>)}
          </div>
        </div>

        <div className="w-auto flex justify-end items-center space-x-8">
          <button className="relative w-24 h-11 bg-white bg-opacity-20 rounded-full dark:text-slate-300 text-white py-1 px-3 flex justify-around items-center" onClick={onCartIconClick} aria-label="Open cart tray">
            <ShoppingCartIcon className=" dark:text-slate-300  w-5 h-5" />
            <span>Cart</span>
            {cartQuantity > 0 && <div className="absolute rounded-full -top-2 -right-2 bg-primary-600 w-6 h-6">
                {cartQuantity}
              </div>}
          </button>
          <UserButton onSignOutClick={onLogOutClick} isSignedIn={isSignedIn} />
        </div>
      </div>
    </header>;
}
_s(Header, "p8SQDOOKcp4gex6Qsmp1aN/e6Zk=", false, function () {
  return [useRootLoader];
});
_c = Header;
var _c;
$RefreshReg$(_c, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;